import { useState } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import { BarLoader } from '../../components/BarLoader/BarLoader';

export const CourseEnPopup = ({course}) => {

	const { postRequest ,getRequest } = useServer();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(true);
  
	const openModal = () => {
		setIsModalOpen(true);
		setLoading(true);
        getRequest(endpoints.getCoursev2 + course.id + '/en/get', {}, (res) => { 
          if (res[0]){
            const resI = res[1];
            setTitle(resI.en.name);
            setDescription(resI.en.description);
          }
          setLoading(false);
        });
		
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
   

	const handleSubmit = () => {
        let data = {
            name: title,
            description: description
        }
        postRequest(endpoints.updateCourseEn + course.id + '/en/update', data, (res) => { 
            if (res[0]) {
                closeModal();
            } else {
                closeModal();
            }
        });
  	};

    return (
        <div>
			
            <button className="title-button" onClick={openModal}><i className="fa-solid fa-language" ></i> English</button>
			
			{isModalOpen && (
				<div className="modal" >
				<div className="modal-content">
				<h2>English Course</h2>
				{loading && 
                    (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
                
				<i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
					<div className='item-form-create-academy'>
					<input type="text" placeholder="Course title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                
					</div>
					<textarea type="text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}> </textarea>
					<div className='item-form-create-academy'>
					</div>
					<div className='item-form-create-academy'>
					<button className='title-button' onClick={closeModal}>Cancel</button>
					<button className='title-button' onClick={handleSubmit}>Save</button>
					</div>
				</div>
				</div>
			)}
		</div>
    );
}


