import { useState ,useRef } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import { useModule } from "../../context/ModuleContext";


const CourseEditPopup = ({ course }) => {
	const { getModule } = useModule();
	const fileInputRef2 = useRef();
	const { postRequest } = useServer();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [regPolicy, setRegPolicy] = useState('');
	const [visibility, setVisibility] = useState('');
  
	const openModal = () => {
		setIsModalOpen(true);
		setTitle(course.name);
		setDescription(course.description);
		setRegPolicy(course.registration_policy);
		setVisibility(course.visibility);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleSubmit = () => {
		var formData = new FormData();
		formData.append("img", fileInputRef2.current.files[0]);
		formData.append("name", title); 
		formData.append("description", description); 
		formData.append("registration_policy", regPolicy);
		formData.append("visibility", visibility);

    postRequest(endpoints.updateCourse + course.id +'/update', formData, (res) => { 
		if (res[0]) {
			closeModal();
			getModule(course.id);
		} else {
			closeModal();
		}
    });
  };


    return (
        <div>
			<button className='title-button' onClick={openModal}>Edit course</button>
			
			{isModalOpen && (
				<div className="modal" >
				<div className="modal-content">
				<h2>Edit course</h2> 
				<i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
					<div className='item-form-create-academy'>
					<input type="text" placeholder="Course title" value={title} onChange={(e) => setTitle(e.target.value)}/>
					<input type="file" accept="image/*" ref={fileInputRef2}/>
					</div>
					<textarea type="text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}> </textarea>
					<div className='item-form-create-academy'>
					<label>Registration policy: 
					<select className='select-oprions' value={regPolicy} onChange={(e) => setRegPolicy(e.target.value)}>
						<option value="0">By invitation</option>
					</select></label>
					<label>Visibility:
					<select className='select-oprions' value={visibility} onChange={(e) => setVisibility(e.target.value)}>
						<option value="0">Visible to everyone</option>
						<option value="1">Registered users only</option>
						<option value="2">Members only</option>
						<option value="3">Hidden (Link only)</option>
						<option value="4">Staff only</option>
					</select></label>
					</div>
					
					<div className='item-form-create-academy'>
					<button className='title-button' onClick={closeModal}>Cancel</button>
					<button className='title-button' onClick={handleSubmit}>Save</button>
					
					</div>
				</div>
				</div>
			)}
		</div>
    );
}

export default CourseEditPopup;
