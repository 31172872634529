export const backend = {
    url: 'https://backendcursos.johnhanleyphd.com'
   // url: 'http://localhost:8000'
}

export const endpoints = {
    login: '/api/v1/auth/login',
    checkAuth: '/api/v1/auth/check',
    logout: '/api/v1/auth/logout',
    getCourses: 'api/v1/course/getall',
    getCourse: '/api/public/v2/course/get',
    order: '/api/v1/course/',
    search: '/api/v1/course/search',
    create: '/api/v1/course/create',
    getCoursev1: '/api/v1/course/',
    getImage: '/api/public/v1/course/',
    ordermodule: '/api/v1/module/',
    createModule:'/api/v1/course/',
    deleteModule: '/api/v1/module/',
    updateCourse: '/api/v1/course/',
    getCoursev2: '/api/v1/course/',
    updateCourseEn: '/api/v1/course/',
    getMembers: '/api/v1/course/',
    getUserSearch: '/api/v1/platform_user/search',
    addMember: '/api/v1/course/',
    deleteMember: '/api/v1/course/member/',
    moduleEn: '/api/v1/module/',
    updateModuleEn: '/api/v1/module/',
    updateModule: '/api/v1/module/',
    createArticle: '/api/v1/module/',
    uploadVideo: '/api/v1/article/upload/video',
    uploadFile: '/api/v1/article/upload/file',
    deleteArticle: '/api/v1/article/',
    getEnArticle: '/api/v1/article/', 
    uploadEnArticle: '/api/v1/article/',
    updateOptionArticle: '/api/v1/article/',
    getVideo: '/api/v1/article/',
    getFile: '/api/v1/article/',
    updateArticle: '/api/v1/article/',
    sortArticle: '/api/v1/article/',
    getTimes: '/api/v1/time/get',
    getSell: '/api/v1/course/',
    updateSell: '/api/v1/course/',
    updateStripeGateway: '/api/v1/stripegateway/update',
    getStripeGateway: '/api/v1/stripegateway/get',
    importMembers: '/api/v1/importmembers/get'
}