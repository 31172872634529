import { useState } from "react";
import { useModule } from '../../context/ModuleContext';
import { endpoints } from "../../env";
import { useServer } from "../../context/serverContext";
import { BarLoader } from '../../components/BarLoader/BarLoader';

const DeleteArticle = ({id, article}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { getModule } = useModule();
    const { postRequest } = useServer();
  
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);   
    };

	const deleteArticle = () => {
        
        setLoading(true);
        postRequest(endpoints.deleteArticle + article.id + '/delete', {},(res) => { 
            setLoading(false);
            if (res[0]) {
                closeModal();
                getModule(id);
            } else {
                closeModal();  
            }
           
        });

	};

    return (
        <div>
        <i className="fa-solid fa-trash-can btn-delete-module" onClick={openModal}> </i>
        {isModalOpen && (
            <div className="modal" >
                <div className="modal-content-delete-module">
                <i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
                    <h2>Delete Article: </h2>
                    <p>{article.name}</p> 
                    <p>This action can´t be undone. Are you sure you want to delete this Article?</p>
                    <div className='item-form-create-academy'>
                        <button className='title-button' onClick={closeModal}>Cancel</button>
                        <button className='title-button' onClick={() => deleteArticle()}>Please Confirm</button>
                    </div>
                    {loading && 
                        (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                        )
                    }
                </div>
            </div>
        )}
    </div>
    );
}

export default DeleteArticle;
