import './module_item.css';
import { useModule } from "../../context/ModuleContext";
import DeleteModule from '../DeleteModule/DeleteModule';
import ModuleEnPopup from '../ModuleEnPopup/ModuleEnPopup';
import { ModuleUpdatePopup } from '../ModuleupdatePopup/ModuleUpdatePopup';
import CreateArticlePopup from '../CreateArticlePopup/CreateArticlePopup';
import DeleteArticle from '../DeleteArticle/DeleteArticle';
import ArticleEnPopup from '../ArticleEnPopup/ArticleEnPopup';
import ArticleOptionPopup from '../ArticleOptionPopup/ArticleOptionPopup';
import ArticleViewPopup from '../ArticleViewPopup/ArticleViewPopup';
import ArticleUpdatePopup from '../ArticleUpdatePopup/ArticleUpdatePopup';
import ArticleMovePopup from '../ArticleMovePopup/ArticleMovePopup';
import ArticleTime from '../ArticleTime/ArticleTime';

export const ModuleItem = ({ module, index, id, handleDragStart,handleDragOver,handleDrop,handleDragEnter,handleDragLeave,handleDragEnd, dragOverModuleIndex }) => {
    
    return (
        <>

        <div className='module-container'>
            <div
            className={dragOverModuleIndex === index ? "list-item-module next-position-module" : "list-item-module"}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={handleDragOver}
            onDrop={() => handleDrop(index)}
            onDragEnter={() => handleDragEnter(index)}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragEnd}>
                <p><i className="fa-solid fa-bars"></i> {module.name}</p>
                    <span className='course-view-body-module-options'>
                        <ArticleMovePopup articless={module} id={id}></ArticleMovePopup>

                        <DeleteModule id={id} module={module}></DeleteModule>
                        <ModuleEnPopup module={module}></ModuleEnPopup>
                        <ModuleUpdatePopup module={module} id={id}></ModuleUpdatePopup>
                        <CreateArticlePopup module={module} id={id}></CreateArticlePopup>

                    </span>
            </div>
            {module.articles &&
                    module.articles.map((article, articleIndex) => (
                        <div className='content-article-bar-progress'  key={articleIndex}>
                        <div className="list-item-article ">
                            <p><i className="fa-regular fa-newspaper"></i> {article.name}</p>
                            <span className='course-view-body-article-options'> 
                                    <ArticleEnPopup article={article}></ArticleEnPopup>
                                    <ArticleOptionPopup idCourse={id} article={article}></ArticleOptionPopup>
                                    <ArticleViewPopup article={article}></ArticleViewPopup>
                                    <ArticleUpdatePopup idCourse={id} article={article}></ArticleUpdatePopup>
                                    <DeleteArticle id={id} article={article}></DeleteArticle>   
                            </span>
                        </div>
                        <ArticleTime article={article} ></ArticleTime>
                        </div>
                    ))
                }
                 </div> 
                <br />
           
        </>
    );
}




