import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { backend, endpoints } from "../env";

const AuthContext = createContext(undefined);

export const AuthProvider = ({children}) => {
    const api = axios.create({
        baseURL: backend.url,
        withCredentials: true,
        headers: {
            'api_request': true,
        }
    });

    //STATES
    const [isAuth, setIsAuth] = useState(null);
    const [authUser, setAuthUser] = useState(null);

    const login = (email, password, callback) => {
        let data = {
            email,
            password
        }
        api.post(endpoints.login, data).then((data) => {
            if (data.data.success) {
                setIsAuth(true);
                setAuthUser(data.data.user);
            }
            callback([true, data.data]);
            }).catch((res) => {
                setIsAuth(false);
                callback([false, res]);
            });
    }

    const checkAuth = () => {
        api.get(endpoints.checkAuth).then(data => {
            const res = data.data;
            if (res.success) {
                setIsAuth(true);
                setAuthUser(res.user);
            } else {
                setIsAuth(false);
                setAuthUser(null);
            }
        });
    }

    const logoutAuth = () => {
        api.get(endpoints.logout).then(data => {
            const res = data.data;
            if (res.success) {
                setIsAuth(false);
                setAuthUser(null);
            }
        });
    }

    useEffect(() => {
        checkAuth();
    }, []);


    const value = {
        login,
        checkAuth,
        isAuth,
        authUser,
        logoutAuth
    }

    return (
        <AuthContext.Provider
            value={value}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);