import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth} from "./../../context/AuthContext";


export function ProtectRoute(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuth } = useAuth();

    useEffect(() => {
        if (location) {
            if (location.pathname === props.path && isAuth === false) {
                navigate('/login');
            }
        }
    }, [location, isAuth]);

    return <></>
}