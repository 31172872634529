import { useCourse } from '../../context/CourseContext';
import './article_time.css';
import React from 'react';


const ArticleTime = ({article}) => {

    const { timeArticles } = useCourse();

    return (
        <>
        {timeArticles && timeArticles.map((timeArticle) => (
            <div className='div-progress' key={timeArticle.id}>
                {article.id === timeArticle.article_id && (
                    <>
                        <progress className='progress-label' id="file-progress" value={(timeArticle.time/article.duration)*100} max="100"></progress>
                    </>
                   
                )}
            </div>
        ))}
        </>   
    );
}

export default ArticleTime;
