import './login.css';
import { useNavigate } from 'react-router-dom';
import imageLogoHanley from '../../assets/img/png/john_logo_white.png';
import { useState } from 'react';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useAuth } from '../../context/AuthContext';


export function Login() {
    //HOOKS
    const navigate = useNavigate();
    const {login} = useAuth();

    //STATES
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState("");
	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");

    //FUNCTIONS
	const postLogin = () => {
        if (loading) {
            return;
        }
        setErrorMsg("");
        setLoading(true);

		login(email, pwd, (res) => {
            setLoading(false);
            
            if (res[0]) {
                res = res[1];
                if (res.success) {
                    //checkAuth();
                    navigate('/dashboard', {replace: true});
                } else {
                    setErrorMsg("Email or password wrong.");
                }
            } else {
                setErrorMsg("Server error, please try again in a bit");
            }
		});
	}

	return (
		<>
            <div className="login_form_body">
                <div className='form'>
                    <img className='logo' src={imageLogoHanley} alt='' />
                    <div className='form__group'>
                        <input className='form__field' type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='form__group'>                     
                        <input className='form__field' type="password"  placeholder="Password" value={pwd} onChange={(e) => setPwd(e.target.value)} />
                    </div>

                    {errorMsg !== "" && 
                        (
                            <div className='error_msg'>
                                {errorMsg}
                            </div>
                        )
                    }
                    <button className='light_btn' onClick={postLogin}> Login</button>
                    {loading && 
                        (
                            <div className='loader'>
                                <BarLoader />
                            </div>
                        )
                    }
                </div>
            </div>
		</>
	);
}    