import { useState , useRef } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import Editor from '@monaco-editor/react';
import { useModule } from "../../context/ModuleContext";
import ArticleVideoPopup from '../ArticleVideoPopup/ArticleVideoPopup';
import ArticleFilePopup from '../ArtivleFilePopup/ArticleFilePopup';

const ArticleUpdatePopup = ({idCourse, article}) => {
    const { postRequest } = useServer();
    const { getModule } = useModule();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [content, setContent] = useState(article.content);

    const popupVideo1 = useRef();
	const popupFile1 = useRef();

    const openModal = () => {
		setIsModalOpen(true); 
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

    const createSubmit = () => {
    if(article.type == 1 || article.type == 3){
			let data = {
				content: content,
			};
			postRequest(endpoints.updateArticle + article.id + '/update', data, (res) => { 
				if (res[0]) {
					closeModal();
					getModule(idCourse);
				} else {
					closeModal();  
				}
			});
		}
    };

	const uploadfile = () => {
		if (article.type == 0) {
			// video
			popupVideo1.current.setIdArticle(article.id);	
			closeModal();
			popupVideo1.current.openModal();	
		}else{
			// file
			popupFile1.current.setIdArticle(article.id);
			closeModal();
			popupFile1.current.openModal();
		}  
	}

    let contentInput;

    switch (article.type) {
        case 0:
          // Video type
          contentInput = ( <button className='title-button' onClick={uploadfile}>Upload Video</button>);
          break;
        case 1:
          // Video Link type
          contentInput = (<input type="text" placeholder="Video Link URL"  value={content} onChange={(e) => setContent(e.target.value)}/>);
          break;
        case 2:
          // File type
          contentInput = ( <button className='title-button' onClick={uploadfile}>Upload File</button>);
          break;
        case 3:
          // HTML type
          contentInput = (<Editor height="30vh" theme="vs-dark"  defaultLanguage="html" value={content} onChange={(value) => setContent(value)} />);
          break;
      }

    return (
        <>
            <div>
                <i className="fa-solid fa-pencil btn-en-module" onClick={openModal} ></i>

                {isModalOpen && (
                    <div className="modal" >
                    <div className="modal-content">
                    <h2 className='title-en-module'>Update Article </h2>
                    <i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
                        
                        <div className="item-form-create-academy">{contentInput}</div>
                    
                        <div className='item-form-create-academy'>
                            <button className='title-button' onClick={closeModal}>Cancel</button>
                            <button className='title-button' onClick={createSubmit}>Save</button>
                        </div>
                    </div>
                    </div>
                )}
            </div>
            <ArticleVideoPopup idCourse={idCourse} ref={popupVideo1}  />
            <ArticleFilePopup idCourse={idCourse} ref={popupFile1}  />
        </>
    );
}

export default ArticleUpdatePopup;
