import { useState } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useModule } from "../../context/ModuleContext";

const ArticleOptionPopup = ({idCourse, article}) => {
    const { postRequest } = useServer();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [title, setTitle] = useState('');
    const [duration, setDuration] = useState('');
	const [visibility, setVisibility] = useState('');
    const [loading, setLoading] = useState(false);
    const { getModule } = useModule();

    const openModal = () => {
		setIsModalOpen(true);
        setTitle(article.name);
        setDuration(article.duration);
        setVisibility(article.visibility);
	};

	const closeModal = () => {
		setIsModalOpen(false);
        setTitle('');
        setDuration('');
        setVisibility('');
	};

    const updateSubmit = () => {
        let data = {
            name: title,
            duration: duration,
            visibility: visibility,
        };
        setLoading(true);
        postRequest(endpoints.updateOptionArticle + article.id + '/update', data, (res) => { 
            setLoading(false);
            if (res[0]) {
                closeModal();
                getModule(idCourse);
            } else {
                closeModal();  
            }
        });
    }; 

    return (
        <div>
        <i className="fa-solid fa-sliders btn-en-module" onClick={openModal} ></i>

        {isModalOpen && (
            <div className="modal" >
            <div className="modal-content">
            <h2 className='title-en-module'>Options Article</h2>
            {loading && 
                (
                    <div className='loader'>
                        <BarLoader />
                    </div>
                )
            }
            
            <i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
                <div className='item-form-create-academy'>
                    <input type="text" placeholder="Course title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                </div>
                <div className='item-form-create-academy'>
						<input type="number" placeholder="00" value={duration} onChange={(e) => setDuration(e.target.value)}/>
					</div>
					<label>Visibility: <br />
						<select className='select-oprions' value={visibility} onChange={(e) => setVisibility(e.target.value)}>
							<option value="0">Visible</option>
							<option value="1">Hidden</option>
							<option value="2">Members Only</option>
						</select>
					</label>
                
                <div className='item-form-create-academy'>
                    <button className='title-button' onClick={closeModal}>Cancel</button>
                    <button className='title-button' onClick={updateSubmit} disabled={loading}>Save</button>
                </div>
            </div>
            </div>
        )}
    </div>
    );
}

export default ArticleOptionPopup;
