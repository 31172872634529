import './module_update_popup.css';
import React, { useState } from 'react';
import { useModule } from '../../context/ModuleContext';

export const ModuleUpdatePopup = ({module, id}) => {
   const { updateModule} = useModule();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [name, setName] = useState('');
	const [visibility, setVisibility] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
    setName(module.name);
	setVisibility(module.visibility);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setName('');
	setVisibility('');
   
  };

	const createSubmit = () => {
		let data = {
            name: name,
            visibility: visibility,
        };
		if(updateModule(data, id, module.id)){
			closeModal();
		}else{
			closeModal();
		}	
	};

	return (
		<div>
			
            <i className="fa-solid fa-pencil btn-en-module" onClick={openModal}></i>
			
			{isModalOpen && (
				<div className="modal" >
					<div className="modal-content">
						<h2>Edit Module</h2>
						<i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
						<div className='item-form-create-academy'>
							<input type="text" placeholder="Module Name" value={name} onChange={(e) => setName(e.target.value)}/>
						</div>
						<label>Visibility: <br />
							<select className='select-oprions' value={visibility} onChange={(e) => setVisibility(e.target.value)}>
								<option value="0">Visible</option>
								<option value="1">Hidden</option>
								<option value="2">Members Only</option>
							</select>
						</label>

						<div className='item-form-create-academy'>
							<button className='title-button' onClick={closeModal}>Cancel</button>
							<button className='title-button' onClick={createSubmit}>Update</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}