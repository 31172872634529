import './course_item.css';
import { useNavigate } from 'react-router-dom';

export const CourseItem  = ({ course, index, dragOverItemIndex, handleDragStart, handleDragOver, handleDrop, handleDragEnter, handleDragLeave, handleDragEnd }) => {
 
    const navigate = useNavigate();
    
    const goToCourse = () => {
        navigate('/course/' + course.id, {replace: true, state: {course: course}});
    }
     
    return (
        <>
        <div> 
            <div
                className={dragOverItemIndex === index ? "list-item next-position" : "list-item"}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(index)}
                onDragEnter={() => handleDragEnter(index)}
                onDragLeave={handleDragLeave}
                onDragEnd={handleDragEnd}>
                <div className='item-option'>
                    <span><i className="fa-solid fa-arrows-up-down-left-right"></i></span>
                    <p>{course.name}</p>
                </div>
                <div className='item-option'>
                    <p>{course.visibility === 0 ? 'public' : 'private'}</p>
                    <p>by invitation</p> 
                    <i className="edit-course fa-solid fa-pen-to-square" onClick={goToCourse}></i>
                </div>
            </div>
        </div>       
        </>  
    );
};