import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "./context/AuthContext";
import { Login } from "./pages/login/Login";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { ProtectRoutes } from './components/ProtectRoute/ProtectRoutes'
import Courses from "./pages/courses/Courses";
import CourseView from "./pages/course_view/CourseView";
import Setting from "./pages/setting/Setting";


export function App() {

	const {checkAuth} = useAuth();

	useEffect(() => {
		checkAuth();
	}, []);

	return (
		<>
			<Routes>
				<Route path="/login" element={<Login />} />
			</Routes>

			<ProtectRoutes>
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/courses" element={<Courses />} />
				<Route path="/course/:id" element={<CourseView />} />
				<Route path="/setting" element={<Setting />} />
				
			</ProtectRoutes>
		</>
	);
}

export default App;
