import './article_move_popup.css';
import { useState, useRef, useEffect } from 'react';
import { endpoints } from "../../env";
import { useServer } from "../../context/serverContext";
import { useModule } from "../../context/ModuleContext";

const ArticleMovePopup = ({articless, id}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const[articles, setArticle] = useState(articless.articles);
    const { postRequest } = useServer();
    const { getModule, loading, course, modules, setModules, image} = useModule();

    const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
        getModule(id);

	};
    const dragArticle = useRef(null);
    const draggedOverArticle = useRef(null);

    const handleDragEnter = index => {
        draggedOverArticle.current = index;
    }  

    function handleSort() {
        const articleClone = [...articles];
        const draggedArticle = articleClone[dragArticle.current];
        articleClone.splice(dragArticle.current, 1);
        articleClone.splice(draggedOverArticle.current, 0, draggedArticle);

        if (!isNaN(draggedOverArticle.current + 1)) {
            order(draggedOverArticle.current + 1, draggedArticle.id);
        }
        setArticle(articleClone);
    }
    const order = (target, id) => {
        postRequest(endpoints.sortArticle + id+"/sort", { target: target }, (res) => {    
        });
    }
   
    return (
        <div>
            <i className="fa-solid fa-up-down-left-right btn-en-module" onClick={openModal}></i>
            {isModalOpen && (
                <div className="modal" id='modal-move-article'>
                    <div className="modal-content" id='modal-content-move-article'>
                    <i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
                        <h2>Move Article</h2>
    
                        {articles &&
                            articles.map((article, Index) => (
                                <div
                                    className={draggedOverArticle.current === Index ? "list-item-module next-position-module" : "list-item-module"}
                                    key={Index}
                                    draggable
                                    onDragStart={() => (dragArticle.current = Index)}
                                    onDragEnter={() => (draggedOverArticle.current = Index)}
                                    onDragEnd={handleSort}
                                    onDragOver={(e) => e.preventDefault()} 
                                    onDragLeave={() => (draggedOverArticle.current = null)}
                                >
                                    <p>
                                        <i className="fa-solid fa-up-down-left-right"></i> {article.name}
                                    </p>
                                </div>
                            ))
                        }
    
                        
                    </div>
                </div>
            )}
        </div>
    );
}

export default ArticleMovePopup;
