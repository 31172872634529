import './courses.css';
import {useEffect, useState} from 'react';
import { Header } from '../../components/header/Header';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import { CourseItem } from '../../components/CourseItem/CourseItem';
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { CoursePopup } from '../../components/CoursePopup/CoursePopup';
import { useCourse } from "../../context/CourseContext";


const Courses = () => {
    //HOOKS
    const { postRequest } = useServer();
    const { courses, setCourses, handleInputChange, searchValue, getCourses, loading } = useCourse();

    //STATES
    const [dragItemIndex, setDragItemIndex] = useState();
    const [dragOverItemIndex, setDragOverItemIndex] = useState();

    useEffect(() => {
       getCourses();
    }, []);

    const handleDragStart = index => {
        setDragItemIndex(index)
    }

    const handleDragOver = event => {
        event.preventDefault();  
    }
    const handleDrop = () => {
        const _courses = [...courses];
        const dragItem = _courses.splice(dragItemIndex, 1);
        _courses.splice(dragOverItemIndex, 0, dragItem[0]);
        const indexItem = (dragOverItemIndex+1);

        const draggedCourseId = dragItem[0].id;
        if (!isNaN(indexItem)) {
            order(indexItem,draggedCourseId);
            setCourses(_courses);
        }
    }
       
    const handleDragEnter = index => {
        setDragOverItemIndex(index)
    }

    const handleDragLeave = (event) => {
        setDragOverItemIndex(undefined)
    }

    const handleDragEnd = event => {
        setDragItemIndex(undefined);
        setDragOverItemIndex(undefined);
    }

    const order = (target, id) => {
        postRequest(endpoints.order + id+"/order", { target: target }, (res) => {    
        });
    }

    
  
    return (
    <>
        <Header></Header>
        <div className='container-courses'>
            <div className='container-courses-header'>
                <div>
                    <h2>Courses</h2>
                    <CoursePopup></CoursePopup>
                </div>
                <input placeholder="Search..." className='search-input' type="text"value={searchValue} onChange={handleInputChange} />
            </div>

            {loading && 
                (
                    <div className='loader'>
                        <BarLoader />
                    </div>
                )
            }

            <div className='list-courses'>
                {courses &&
                    courses.map((course, index) => (
                        <CourseItem
                            key={index}
                            course={course}
                            index={index}
                            dragOverItemIndex={dragOverItemIndex}
                            handleDragStart={handleDragStart}
                            handleDragOver={handleDragOver}
                            handleDrop={handleDrop}
                            handleDragEnter={handleDragEnter}
                            handleDragLeave={handleDragLeave}
                            handleDragEnd={handleDragEnd}
                        />     
                    ))
                }
            </div>  
        </div>  
    </>
    );
}

export default Courses;
