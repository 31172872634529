import './create_article_popup.css';
import { useRef, useState } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import Editor from '@monaco-editor/react';
import { useModule } from "../../context/ModuleContext";
import ArticleVideoPopup from '../ArticleVideoPopup/ArticleVideoPopup';
import ArticleFilePopup from '../ArtivleFilePopup/ArticleFilePopup';


const CreateArticlePopup = ({module, id}) => {
	const { postRequest } = useServer();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [content, setContent] = useState('');
    const [duration, setDuration] = useState('');
	const [visibility, setVisibility] = useState('');
	const { getModule } = useModule();

	const popupVideo = useRef();
	const popupFile = useRef();
	
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setName('');
        setType('');
        setContent('');
        setDuration('');
        setVisibility('');
    };

    const createSubmit = () => {
		if(type == 1 || type == 3){
			let data = {
				name: name,
				type: type,
				content: content,
				duration: duration,
				visibility: visibility,
			};
			postRequest(endpoints.createArticle + module.id + '/article/create', data, (res) => { 
				if (res[0]) {
					closeModal();
					getModule(id);
				} else {
					closeModal();  
				}
			});
		}else if (type == 0) {
			// video
			let data = {
				name: name,
				type: type,
				duration: duration,
				visibility: visibility,
			};
			postRequest(endpoints.createArticle + module.id + '/article/create', data, (res) => { 
				if (res[0]) {
					closeModal();
					popupVideo.current.setIdArticle(res[1].article.id);
					popupVideo.current.openModal();	
				} else {
					closeModal();  
				}
			});
		}else{
			// file
			let data = {
				name: name,
				type: type,
				duration: duration,
				visibility: visibility,
			};
			postRequest(endpoints.createArticle + module.id + '/article/create', data, (res) => { 
				if (res[0]) {
					closeModal();
					popupFile.current.setIdArticle(res[1].article.id);
					popupFile.current.openModal();
				} else {
					closeModal();  
				}
			});
		}
    }; 

    let contentInput;

    switch (type) {
      case '0':
        // Video type
		contentInput = (<p>Video</p>);
        break;
      case '1':
        // Video Link type
        contentInput = (<input type="text" placeholder="Video Link URL"  value={content} onChange={(e) => setContent(e.target.value)}/>);
        break;
      case '2':
        // File type
	    contentInput = (<p>File</p>);
        break;
      case '3':
        // HTML type
        contentInput = (<Editor height="15vh" theme="vs-dark"  defaultLanguage="html" value={content} onChange={(value) => setContent(value)} />);
        break;
    }

		return (
			<>
			<div>
			<i className="fa-solid fa-file-circle-plus btn-add-article"  onClick={openModal}> </i>
			{isModalOpen && (
			<div className="modal" >
				<div className="modal-content">
					<h2>Create Article</h2>
					<i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
					<div className='item-form-create-academy'>
						<input type="text" placeholder="Article Name" value={name} onChange={(e) => setName(e.target.value)}/>
					</div>
					<label>Type: <br />
						<select className='select-oprions' value={type} onChange={(e) => setType(e.target.value)}>
							<option value="0">Video</option>
							<option value="1">Video Link</option>
							<option value="2">File</option>
							<option value="3">HTML</option>
						</select>
					</label>            

					<div className='item-form-create-academy'>
						<input type="number" placeholder="00" value={duration} onChange={(e) => setDuration(e.target.value)}/>
					</div>
					<label>Visibility: <br />
						<select className='select-oprions' value={visibility} onChange={(e) => setVisibility(e.target.value)}>
							<option value="0">Visible</option>
							<option value="1">Hidden</option>
							<option value="2">Members Only</option>
						</select>
					</label>

					<div className='item-form-create-academy'>
						{contentInput}
					</div>

					<div className='item-form-create-academy'>
						<button className='title-button' onClick={closeModal}>Cancel</button>
						<button className='title-button' onClick={createSubmit}>Save</button>
					</div>
				</div>
			</div>
			)} 
		</div>
		<ArticleVideoPopup idCourse={id} ref={popupVideo}  />
		<ArticleFilePopup idCourse={id} ref={popupFile}  />
	
		</>
		
		);
	}

export default CreateArticlePopup;
