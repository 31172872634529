import { useState } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import { BarLoader } from '../../components/BarLoader/BarLoader';

const ArticleEnPopup = ({article}) => {
    const { postRequest ,getRequest } = useServer();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);
  
	const openModal = () => {
		setIsModalOpen(true);
		setLoading(true);
        getRequest(endpoints.getEnArticle + article.id + '/en/get', {}, (res) => { 
          if (res[0]){
            const resI = res[1];
            setTitle(resI.en.name);   
          }
          setLoading(false);
        });
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleSubmit = () => {
        let data = {
            name: title
        }
        setLoading(true);
        postRequest(endpoints.uploadEnArticle + article.id + '/en/update', data, (res) => { 
            setLoading(false);
            if (res[0]) {
                closeModal();
            } else {
                closeModal();
            }
        });
  	};




    return (
         <div>
            
            <i className="fa-solid fa-language btn-en-module" onClick={openModal}></i> 

			{isModalOpen && (
				<div className="modal" >
				<div className="modal-content">
				<h2 className='title-en-module'>English Article</h2>
				{loading && 
                    (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
                
				<i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
					<div className='item-form-create-academy'>
					    <input type="text" placeholder="Course title" value={title} onChange={(e) => setTitle(e.target.value)}/>
					</div>
					
					<div className='item-form-create-academy'>
                        <button className='title-button' onClick={closeModal}>Cancel</button>
                        <button className='title-button' onClick={handleSubmit} disabled={loading}>Save</button>
					</div>
				</div>
				</div>
			)}
		</div>
    );
}

export default ArticleEnPopup;
