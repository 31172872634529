import './dashboard.css';
import { useAuth } from '../../context/AuthContext';
import { Header } from '../../components/header/Header';
import imageCourses from '../../assets/img/jpg/courses_btn.jpg';
import imageUserManagment from '../../assets/img/jpg/users_btn.jpg';
import imageAdmin from '../../assets/img/jpg/admins_btn.jpg';
import { useNavigate } from 'react-router-dom';

export function Dashboard() {
	//HOOK
	const navigate = useNavigate();
	
	const action2 = () => {
		alert("User");
	};

    return (
		<>
			<Header></Header>
			<div className="body-dashboard">
				<div className='item-dashboard' onClick={() =>{navigate('/courses')}}>
					<img className='img' src={imageCourses} alt="" />
					<span>Courses</span>
				</div>
				<div className='item-dashboard' onClick={action2}>
					<img className='img' src={imageUserManagment} alt="" />
					<span>User Managment</span>
				</div>
				<div className='item-dashboard' onClick={() =>{navigate('/setting')}}>
					<img className='img' src={imageAdmin} alt="" />
					<span>Settings</span>
				</div>
			</div>
			
		</>
	);
}