import './delete_module.css';
import { useState } from "react";
import { useModule } from '../../context/ModuleContext';

const DeleteModule = ({id, module}) => {

    const { deleteModule } = useModule();
	const [isModalOpen, setIsModalOpen] = useState(false);
  
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);   
    };

	const deleteM = () => {
        deleteModule(module.id, id);
		closeModal();	
	};

    return (
    <div>
        <i className="fa-solid fa-trash-can btn-delete-module" onClick={openModal}> </i>
        {isModalOpen && (
            <div className="modal" >
                <div className="modal-content-delete-module">
                <i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
                    <h2>Delete Module:</h2>
                    <p>{module.name}</p> 
                    <div className='item-form-create-academy'>
                        <button className='title-button' onClick={closeModal}>Cancel</button>
                        <button className='title-button' onClick={() => deleteM()}>Please Confirm</button>
                    </div>
                </div>
            </div>
        )}
    </div>
    );
}

export default DeleteModule;
