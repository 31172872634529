import './setting.css';
import React from 'react';
import { Header } from '../../components/header/Header';
import { StripePopup } from '../../components/StripePopup/StripePopup';

const Setting = () => {
    return (
        <>
        <Header></Header>
        <div className='body-setting'>
            <div className='div-Settings'>
                <h2>Settings</h2>
            
                <div className='section-div-settings'>
                    <div class="content">
                        <h3 class="order_enable_text">Accept Payments</h3>
                        <span>Choose the way you get paid by customers.</span>
                    </div>
                    <hr></hr>
                    <div className='div-stripe'>
                        <div>
                        <i class="fa-brands fa-stripe"></i> 
                        <span>Payment Methods: Credit/debit cards</span>
                        </div> 
                        <StripePopup></StripePopup>
                    </div>
                </div>

            </div>

            

        </div>


            
        </>
    );
}

export default Setting;
