import { useState } from 'react';
import { useServer } from "../../context/serverContext";
import { backend, endpoints } from "../../env";


const ArticleViewPopup = ({ article }) => {
	const { getRequest } = useServer();
	const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
		setIsModalOpen(true); 
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

    let contentInput;
	

    switch (article.type) {
        case 0:
          // Video type
		 
          contentInput = (<video controls width="100%" controlsList="nodownload" key={article.id}  >
			<source src={backend.url + "/api/v1/article/"+article.id+"/video/get"} type="video/mp4" key={article.id} /></video>);
          break;
        case 1:
          // Video Link type
          contentInput = (<video controls width="100%" controlsList="nodownload"><source src={article.content} type="video/mp4" /></video>);
			break;
        case 2:
          // File type
          contentInput = ( <div><h3>{article.content}</h3><p onClick={() => download()}>Download</p></div>);
          break;
        case 3:
          // HTML type
          contentInput = article.content;
          break;
        default:
          contentInput = <p>Unknown Type</p>;
      }
		const download = () => {
			getRequest(endpoints.getFile + article.id + '/file/get', {}, (res) => { 
				console.log(res);
				
			});
		}
		

	

    return (
        <div>
        <i className="fa-solid fa-eye btn-en-module" onClick={openModal} ></i>

        {isModalOpen && (
            <div className="modal" >
            <div className="modal-content">
            <h2 className='title-en-module'>View Article </h2>
            
            <i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>

				{article.type === 3 ? (
                    <div dangerouslySetInnerHTML={{ __html: contentInput }}></div>
                ) : (
                    <div className="item-form-create-academy">{contentInput}</div>
                )}


                <div className='item-form-create-academy'>
                    <button className='title-button' onClick={closeModal}>Cancel</button>
                </div>
            </div>
            </div>
        )}
    </div>
    );
}

export default ArticleViewPopup;
