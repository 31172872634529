import './article_video_popup.css';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Resumable from 'resumablejs';
import { endpoints, backend } from "../../env";
import { useModule } from "../../context/ModuleContext";

const ArticleVideoPopup = forwardRef(({ idCourse }, ref) => {
	useImperativeHandle(ref, () => {
		return {
			openModal,
			closeModal,
			idArticle,
			setIdArticle,
		};
	});

	const resumableRef = useRef(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [idArticle, setIdArticle] = useState(0);
	const { getModule, } = useModule();
	const [videoProgress, setVideoProgress] = useState(0);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (idArticle == 0) {
			return;
		}
		// Configurar Resumable.js
		const resumable = new Resumable({
			target: backend.url + endpoints.uploadVideo,
			query: { article: idArticle },
			fileType: ['mp4'],
			headers: {
				'Accept': 'application/json'
			},
			withCredentials: true,
			testChunks: false,
			throttleProgressCallbacks: 1,
			chunkRetryInterval: 1000,
			permanentErrors: [404, 415, 403],
		});
		// Asignar Resumable.js al ref
		resumableRef.current = resumable;

		// Manejar eventos de Resumable.js
		resumable.on('fileAdded', (file) => {
			// START LOADER
			resumable.upload();
		});

		resumable.on('fileProgress', (file) => {
			setVideoProgress(Math.floor(file.progress() * 100));
		});

		resumable.on('fileSuccess', (file, response) => {
			response = JSON.parse(response);
			console.log(response);
			setVideoProgress(0);
			closeModal();
			getModule(idCourse);
		});

		resumable.on('fileError', (file, response) => {
			alert("Ocurrió un error temporal, recarga la página");
			console.error("Error", response);
			closeModal();
		});

		return () => {
			if (resumableRef.current) {
				resumableRef.current = null;
			}
		};
	}, [idArticle]);

	return (
		<>
			{isModalOpen && (
				<div className="modal" >
					<div className="modal-content">
						<h2>Upload Video</h2>
						<input type="file" onChange={(e) => {
							const file = e.target.files[0];
							if (file) { resumableRef.current.addFile(file); }
						}}
						/>

						<div className='bar_progress'>
							<div className='bar_progress_active' style={{ width: `${videoProgress}%` }}>
								{videoProgress}
							</div>
						</div>
						<button className='title-button' onClick={closeModal}>Cancel</button>
					</div>
				</div>
			)}
		</>
	);
});

export default ArticleVideoPopup;