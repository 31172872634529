import { useState } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import { BarLoader } from '../../components/BarLoader/BarLoader';


export const StripePopup = () => {
	const { postRequest, getRequest } = useServer();
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [pk, setPk] = useState('');
    const [sk, setSk] = useState('');
    const [currency, setCurrency] = useState('');
	const [isEnableStripe, setEnableStripe] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
	setLoading(true);
    	getRequest(endpoints.getStripeGateway, {}, (res) => { 
		if(res[0]){
			console.log(res[1]);
			setEnableStripe(res[1].enable);
			setPk(res[1].public);
			setCurrency(res[1].currency);
		}
		setLoading(false);
	  });
	  
  };
  const closeModal = () => {
    setIsModalOpen(false);
	setSk('');
	
  };
  const ClickEnableStripe = () => {
	setEnableStripe(!isEnableStripe);
};
  	const createSubmit = () => {
		let data = {
			stripe_public: pk,
			stripe_secret: sk,
			currency: currency,
			enable: isEnableStripe,
		}
		setLoading(true);
		postRequest(endpoints.updateStripeGateway, data, (res) => { 
			if(res[0]){
				console.log(res[1]);
			}
			setLoading(false);
		});
		closeModal();

  	};

	return (
		<div>
			
            <i className="fa-solid fa-pencil btn-en-module" onClick={openModal}></i>
			
			{isModalOpen && (
				<div className="modal" >
					<div className="modal-content">
						<h2>Edit Stripe</h2>
						{loading && 
							(
								<div className='loader'>
									<BarLoader />
								</div>
							)
						}
						<i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
						<div className='div-enable-price'>
							<span className='title-enable'>Enable Stripe:</span>
							<div className={isEnableStripe ? 'enable-btn enable-disable-btn': 'disable-btn enable-disable-btn'} onClick={ClickEnableStripe}>
								{isEnableStripe ?  <i class="fa-solid fa-check order_enable"></i> :  <i class="fa-solid fa-minus order_disabled"></i>}
							</div>
						</div>

						<div className='item-form-create-academy'>
							<input type="password" placeholder="Public Key" value={pk} onChange={(e) => setPk(e.target.value)}/>
						</div>
                       
                        <div className='item-form-create-academy'>
							<input type="password" placeholder="Secret Key" value={sk} onChange={(e) => setSk(e.target.value)}/>
						</div>

                        <div className='item-form-create-academy'>
							<input type="text" placeholder="Currency" value={currency} onChange={(e) => setCurrency(e.target.value)}/>
						</div>

						<div className='item-form-create-academy'>
							<button className='title-button' onClick={() => closeModal()} >Cancel</button>
							<button className='title-button' onClick={() => createSubmit()} >Update</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}