import { createContext, useContext, useState } from "react";
import imagePlaceholder from '../assets/img/jpg/img_placeholder.jpg';
import { endpoints, backend } from "../env";
import { useServer } from "./serverContext";

const moduleContext = createContext(undefined);

export const ModuleContext = ({children}) => {

    const [modules, setModules] = useState([]);
    const [course, setCourse] = useState();
    const { getRequest, postRequest } = useServer();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(imagePlaceholder);


    const getModule = (id) => {
        setLoading(true);
        getRequest(endpoints.getCoursev1 + id +'/get', {}, (res) => { 
            setLoading(false);
            if (res[0]) {
                res = res[1];
                setCourse(res.course); 
                setModules(res.course.modules);
                setImage(backend.url + endpoints.getImage +id+'/image/get');   
            } else {
            }
        });
    }

    const createModule = (data,id)=>{
        postRequest(endpoints.createModule + id + '/module/create', data, (res) => { 
			if (res[0]) {
                getModule(id);
				return true;  
			} else {
				return false;
			}
		});
    }
    const deleteModule = (idModule, idCourse)=>{
        postRequest(endpoints.deleteModule + idModule + '/delete', {}, (res) => { 
			if (res[0]) {
				getModule(idCourse);
			}
		});
    }
    const updateModule = (data, id, moduleId)=>{
        postRequest(endpoints.updateModule + moduleId + '/update', data, (res) => { 
			if (res[0]) {
                getModule(id);
				return true;  
			} else {
				return false;
			}
		});
    }


    const value = {course,loading,modules,setModules, getModule,createModule,deleteModule, image, updateModule}

    return (
        <moduleContext.Provider value={value}>
            {children}
        </moduleContext.Provider>
    );
}

export const useModule = () => useContext(moduleContext);
