import { createContext, useContext, useState } from "react";
import { endpoints } from "../env";
import { useServer } from "./serverContext";

const courseContext = createContext(undefined);

export const CourseProvider = ({children}) => {

    const { getRequest, postRequest } = useServer();
    const [courses, setCourses] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [timeArticles, setTimeArticles] = useState(null);
    const [timeUser, setTimeUser] = useState(null);


     
    const getCourses = () => {
        setLoading(true);
        getRequest(endpoints.getCourses, {}, (res) => {
            setLoading(false);
            if (res[0]) {
                setCourses(res[1].courses);
            } else {
            }
        });
    }

    const handleInputChange = (event) => {
        const searchValue = event.target.value;
        setSearchValue(searchValue);
        getRequest(endpoints.search, { query: searchValue }, (res) => { 
            if (res[0]) {
                res = res[1];
                setCourses(res.courses);   
            } else {
            }     
        });
    };
    const getTimes = (id) => {
        postRequest(endpoints.getTimes, {platform_user_id : id}, (res) => { 
            if(res[0]){
                setTimeArticles(res[1].times);
                setTimeUser(res[1].user);
            }else{
                console.log("no: ", res);
            }
           
        });
    }


    const value = {
        courses,
        handleInputChange,
        searchValue,
        setCourses,
        getCourses,
        loading,
        timeArticles,
        getTimes,
        timeUser
       
    }

    return (
        <courseContext.Provider value={value}>
            {children}
        </courseContext.Provider>
    );
}

export const useCourse = () => useContext(courseContext);
