import './course_members.css';
import { useState } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { useCourse } from '../../context/CourseContext';

export const CourseMembers = ({course}) => {

    const { postRequest ,getRequest } = useServer();
	const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchValueCourses, setSearchValueCourses] = useState('');
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');

    const [courses, setCourses] = useState([]);
    const [coursesId, setCoursesId] = useState('');
    


   const {getTimes } = useCourse();
  
	const openModal = () => {
		setIsModalOpen(true);
        setError('');
        getMembers();
       
	};

    const getMembers = () => {
        setLoading(true);
        getRequest(endpoints.getMembers + course.id + '/members/list', {}, (res) => { 
          if (res[0]){
            const resI = res[1];
            setMembers(resI.members);
          }
          setLoading(false);
        });

    }

	const closeModal = () => {
		setIsModalOpen(false);
        setSearchValue('');
        setUsers([]);
        setSearchValueCourses('');
        setCourses([]);

	};

    const handleInputChange = (event) => {
        setError('');
        const searchValue = event.target.value;
        setSearchValue(searchValue);
        getRequest(endpoints.getUserSearch, { query: searchValue }, (res) => { 
            if (res[0]) {
                res = res[1];
                setUsers(res.users);
            }    
        });
    };

    const handleInputChangecourses = (event) => {
        const searchValueCourses = event.target.value;
        setSearchValueCourses(searchValueCourses);
        getRequest(endpoints.search, { query: searchValueCourses }, (res) => { 
            if (res[0]) {
                res = res[1];
                setCourses(res.courses);   
            } else {
            }     
        });
    };

    const tomarBtn = (e) => {
        setSearchValue(e.target.getAttribute('value'));
        
    }
    
    const tomarBtnCourses = (e) => {
        setSearchValueCourses(e.target.getAttribute('value'));
        setCoursesId(e.target.getAttribute('data-course-id'))   
    }
    
    const addMember = () => {
        postRequest(endpoints.addMember + course.id + '/members/add', { email: searchValue }, (res) => { 
            if (res[1].success) {
                res = res[1];
                getMembers();
                setSearchValue('');
                setUsers([]);
            }else{
                setError(res[1].msg);
            }   
        });
    }
    const inportMember = () => {
        postRequest(endpoints.importMembers, { courseId: coursesId, courseAct: course.id  }, (res) => { 
            if (res[1].success) {
                res = res[1];
                console.log(res);
                getMembers();
                setSearchValueCourses('');
                setCourses([]);
            }else{
                setError(res[1].msg);
            }   
        });
    }

    const deleteMember = (id) => {
        postRequest(endpoints.deleteMember + id + '/delete', {}, (res) => { 
            getMembers();
        });
    }
    const uploadTime = (idUser) => {
        getTimes(idUser);
        closeModal();
      };

    return (
        <div>			
			<button className="title-button"  onClick={openModal} ><i className="fa-solid fa-users"></i> Members</button>
			{isModalOpen && (
				<div className="modal" >
				<div className="modal-content-members">
                <h2>Members</h2>
                    <div>
                        <div className='members-list-header'><p>ID</p><p>Name</p><p>Email</p> <p>Actions</p></div>
                        <div className='members-container'>
                            {members.map((member, index) => (
                                <div className='members-list' key={index}>   
                                    <p> {member.id}</p>
                                    <p> {member.user.name}</p>
                                    <p> {member.user.email}</p>  
                                    <div className='bar-delete-progress'>
                                    <p className='members-delete'> <i className="fa-solid fa-trash-can" onClick={() => deleteMember(member.id)}></i></p> 
                                    <p className='members-bars-progress'> <i className="fa-solid fa-bars-progress" onClick={() => uploadTime(member.user.id)}></i></p> 
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

				<h2>Add Members </h2>
                <h3 className='msj-error'>{error}</h3>
				{loading && 
                    (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
                
				    <i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
					<div className='item-form-create-academy-header'>
                        <input className='gmail-members' type="text" placeholder="email" value={searchValue}  onChange={handleInputChange} />
                        
                        <div className='container-members-user'>
                            {users.map((user, index) => (
                                <div onClick={tomarBtn} className='members-user' key={index} value={user.email}>
                                    {user.email}
                                </div>
                            ))}
                        </div>                     
					</div>
                    <div>
                    </div>
					<div className='item-form-create-academy-header'>
                        <button className='title-button' onClick={addMember} >Add</button>
					</div>

                    <h2>Inport Academy Members </h2>
                    <div className='item-form-create-academy-header'>
                        <input className='gmail-members' type="text" placeholder="Academy" value={searchValueCourses}  onChange={handleInputChangecourses} />      
                        <div className='container-members-user'>
                            {courses.map((course, index) => (
                                <div onClick={tomarBtnCourses} className='members-user' key={index} value={course.name}  data-course-id={course.id} >
                                    {course.name}
                                </div>
                            ))}
                        </div>                     
					</div>
                    <div className='item-form-create-academy-header'>
                        <button className='title-button' onClick={closeModal}>Cancel</button>
                        <button className='title-button' onClick={inportMember} >Inport</button>
					</div>
				</div>
                


				</div>
			)}
		</div>
    );
}


