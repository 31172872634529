import './header.css';
import {React, useState} from 'react';
import imageLogoHanley from '../../assets/img/png/john_logo_white.png';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export const Header = () => {

    //HOOK
	const {authUser, logoutAuth} = useAuth();
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = () => {
    	setIsHovered(true);
    };
    
    const handleMouseLeave = () => {
    	setIsHovered(false);
    };
 
    return (
        <div className='bar-header'>  
            <img className='logo' src={imageLogoHanley} onClick={() =>{navigate('/dashboard')}} alt='' />
            <div className='bar-header-user' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                <div className='pic'> {authUser && authUser.name.charAt(0)} </div>
                <span className='auth-user'>{authUser && authUser.name}</span>
				<i className="logout-movil pic fa-solid fa-right-from-bracket" onClick={logoutAuth}> </i>
                {isHovered && (<span className='logout-txt' onClick={logoutAuth}>Logout</span>)}
            </div>   
        </div>
    );
}


