import { useServer } from '../../context/serverContext';
import { endpoints } from '../../env';
import './price_update_popup.css';
import {useEffect, useState} from 'react';
import { BarLoader } from '../../components/BarLoader/BarLoader';

const PriceUpdatePopup = ({course}) => {
    const { postRequest, getRequest }= useServer();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEnablePrice, setEnablePrice] = useState(false);
    const [isEnableSale, setEnableSale] = useState(false);
    const [price, setPrice] = useState();
    const [tax, setTax] = useState();
    const [salePrice, setSalePrice] = useState();
    const [loading, setLoading] = useState(true);

    const openModal = () => {
		setIsModalOpen(true);
        setLoading(true);
        getRequest(endpoints.getSell + course.id + '/sell/get', {}, (res) => { 
            setLoading(false);
            if (res[0]) {
                const sell = res[1].sell;
                setEnablePrice(sell.enable);
                setPrice(sell.price);
                setTax(sell.tax);
                setEnableSale(sell.is_sale);
                setSalePrice(sell.sale_price);
            } else {
                closeModal();
            }
        });
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};
    const ClickEnablePrice = () => {
        setEnablePrice(!isEnablePrice);
    };
    const ClickEnableSale = () => {
        setEnableSale(!isEnableSale);
    };

    // Update Data
    const handleSubmit = () => {
        setLoading(true);
        let data = {
            enable: isEnablePrice,
            price: price,
            tax: tax,
            is_sale: isEnableSale,
            sale_price: salePrice,
        };
        postRequest(endpoints.getSell + course.id + '/sell/update', data, (res) => { 
            setLoading(false);
            if (res[0]) {
                const sell = res[1].sell;
            } else {
                closeModal();
            }
        });
		setIsModalOpen(false);

	};

    return (
        <div>
        <button className='title-button' onClick={openModal}><i className="fa-solid fa-hand-holding-dollar"></i> Price</button>
        
        {isModalOpen && (
            <div className="modal" >
                <div className="modal-content">
                    <h2>Edit Price</h2> 
                    <i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
                    {loading && 
                    (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                    }

                    <div className='div-enable-price'>
                        <span className='title-enable'>Enable:</span>
                        <div className={isEnablePrice ? 'enable-btn enable-disable-btn': 'disable-btn enable-disable-btn'} onClick={ClickEnablePrice}>
                            {isEnablePrice ?  <i class="fa-solid fa-check order_enable"></i> :  <i class="fa-solid fa-minus order_disabled"></i>}
                        </div>
                    </div>

                    <div className='div-enable-price'> 
                        <input className='price-regular-input' type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)}/>
                        <input type="number" placeholder="Tax" value={tax} onChange={(e) => setTax(e.target.value)}/> 
                    </div>

                    <div className='div-enable-price'>
                        <span className='title-enable'>Enable Sale:</span>
                        <div className={isEnableSale ? 'enable-btn enable-disable-btn': 'disable-btn enable-disable-btn'} onClick={ClickEnableSale}>
                            {isEnableSale ?  <i class="fa-solid fa-check order_enable"></i> :  <i class="fa-solid fa-minus order_disabled"></i>}
                        </div>
                    </div>
                    <div className='div-enable-price'>
                        <input type="number" placeholder="Sale Price" value={salePrice} onChange={(e) => setSalePrice(e.target.value)}/> 
                    </div>
                    
                   
                    <div className='item-form-create-academy'>
                        <button className='title-button' onClick={closeModal}>Cancel</button>
                        <button className='title-button' onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </div>
        )}
    </div>
    );
}

export default PriceUpdatePopup;
