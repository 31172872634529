import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ServerProvider } from './context/serverContext';
import { CourseProvider } from './context/CourseContext';
import { ModuleContext } from './context/ModuleContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  	<React.StrictMode>
		<BrowserRouter>
			<AuthProvider>
				<ServerProvider>
					<CourseProvider>
						<ModuleContext>	
								<App />
						</ModuleContext>
					</CourseProvider>
				</ServerProvider>
			</AuthProvider>
		</BrowserRouter>
  	</React.StrictMode>
);
