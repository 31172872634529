import './course_view.css';
import { useEffect, useState } from 'react';
import { useParams ,Link } from 'react-router-dom';
import imagePlaceholder from '../../assets/img/jpg/img_placeholder.jpg';
import { Header } from '../../components/header/Header';
import { endpoints , backend } from "../../env";
import { useServer } from "../../context/serverContext";
import { BarLoader } from '../../components/BarLoader/BarLoader';
import { ModuleItem } from '../../components/ModuleItem/ModuleItem';
import { CreateModulePopup } from '../../components/CreateModulePopup/CreateModulePopup';
import { useModule } from "../../context/ModuleContext";
import CourseEditPopup from '../../components/CourseEditPopup/CourseEditPopup';
import {CourseEnPopup} from '../../components/CourseEnPopup/CourseEnPopup';
import {CourseMembers} from '../../components/CourseMembers/CourseMembers';
import { useCourse } from '../../context/CourseContext';
import PriceUpdatePopup from '../../components/PriceUpdatePopup/PriceUpdatePopup';


const CourseView = () => {
   
    const { id } = useParams();
    const { getModule, loading, course, modules, setModules, image} = useModule();
    const { postRequest } = useServer();
    const [dragOverModuleIndex, setDragOverModuleIndex] = useState(null);
    const [dragItemIndex, setDragItemIndex] = useState({moduleIndex: null,articleIndex: null});
    const { timeArticles, timeUser } = useCourse();

    useEffect(() => {
        getModule(id); 
       
    }, []);


      // Module Move
      const handleDragStart = index => {
        setDragItemIndex({ moduleIndex: index, articleIndex: null });
    };   
    const handleDragOver = event => {
        event.preventDefault();  
    }
    const handleDrop = () => {
        const _modules = [...modules];
        const dragModule = _modules.splice(dragItemIndex.moduleIndex, 1);
        _modules.splice(dragOverModuleIndex, 0, dragModule[0]);
        
        const draggedModuleId = dragModule[0].id;
        if (!isNaN(dragOverModuleIndex + 1)) {
            order(dragOverModuleIndex + 1, draggedModuleId);
        }
        setModules(_modules);

    };      
    const handleDragEnter = index => {
        setDragOverModuleIndex(index)
    }   
    const handleDragLeave = (event) => {
        setDragOverModuleIndex(undefined)
    }
    const handleDragEnd = event => {
        setDragItemIndex(undefined);
        setDragOverModuleIndex(undefined);
    }
    const order = (target, id) => {
        postRequest(endpoints.ordermodule + id+"/sort", { target: target }, (res) => {    
        });
    }
   

    return (
        <>
            <Header></Header>
            <div className='path'>
                    <Link to='/courses'>
                    <i className="fa-solid fa-grip"></i> Courses /
                    </Link>
                    <Link to={'/course/'+ (course && course.id) }>
                        &nbsp;{ course && (course && course.name) }
                    </Link>
            </div>
            <div className="course-view-header-banner">
                
            </div>
            <div className='course-view'>
                {loading && 
                    (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            {course && (
                    <>        
                        <div className="course-view-header">
                            {
                                course.picture !== null ? <img className='course-view-header-img' src={image} alt='' /> : <img className='course-view-header-img' src={imagePlaceholder} alt='' />
                            }
                            <p>{course.name}</p>
                        </div>
                        <div className='cuese-header-btn'>     
                            <CourseEditPopup course={course}> </CourseEditPopup>
                            <CourseEnPopup course={course}> </CourseEnPopup>
                            <CourseMembers course={course} id={id}> </CourseMembers>
                            <PriceUpdatePopup course={course}></PriceUpdatePopup>
                        </div>
                        <div className="description">
                            <p>{course.description}</p>
                        </div>
                        <div className="content"> <CreateModulePopup id={id} ></CreateModulePopup></div>  
                    </>
                )}
                {timeArticles && (<> <p>Couse Progress: {timeUser.email}</p>
                
                </>)}
                {modules &&
                    modules.map((module, index) => (
                        <ModuleItem
                            key={index}
                            module={module}
                            index={index}
                            id={id}
                            handleDragStart={handleDragStart}
                            handleDrop={handleDrop}
                            handleDragOver={handleDragOver}
                            handleDragEnter={handleDragEnter}
                            handleDragLeave={handleDragLeave}
                            handleDragEnd={handleDragEnd}
                            dragOverModuleIndex={dragOverModuleIndex}> 

                        </ModuleItem>
                    ))
                }
            </div>      
        </>
    );}

export default CourseView;
