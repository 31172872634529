import './create_module_popup.css';
import React, { useState } from 'react';
import { useServer } from "../../context/serverContext";
import { endpoints } from "../../env";
import { useModule } from '../../context/ModuleContext';


export const CreateModulePopup = ({id}) => {

	const { createModule} = useModule();
	const { postRequest } = useServer();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [visibility, setVisibility] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTitle('');
	setVisibility('');
  };

	const createSubmit = () => {
		let data = {
            name: title,
            visibility: visibility,
        };
		if(createModule(data, id)){
			closeModal();
		}else{
			closeModal();
		}	
	};

	return (
		<div>
			<button className='title-button' onClick={openModal}>Add Module</button>
			
			{isModalOpen && (
				<div className="modal" >
					<div className="modal-content">
						<h2>Create Module</h2>
						<i className="fa-solid fa-circle-xmark icon-close" onClick={()=> closeModal() }></i>
						<div className='item-form-create-academy'>
							<input type="text" placeholder="Module Name" value={title} onChange={(e) => setTitle(e.target.value)}/>
						</div>
						<label>Visibility: <br />
							<select className='select-oprions' value={visibility} onChange={(e) => setVisibility(e.target.value)}>
								<option value="0">Visible</option>
								<option value="1">Hidden</option>
								<option value="2">Members Only</option>
							</select>
						</label>

						<div className='item-form-create-academy'>
							<button className='title-button' onClick={closeModal}>Cancel</button>
							<button className='title-button' onClick={createSubmit}>Save</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}


